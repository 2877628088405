.principles-page {
  height: fit-content;
  background-color: #192e2b;
  color: white;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  justify-content: space-evenly;
  overflow-x: hidden;
  padding: 30px 50px 50px 50px;
  margin: 5vh 2vw;
}

.principle-main-title-container {
  /* margin: 5rem 0rem 0rem; */
  margin-top: 5vh;
}

.principle-main-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Syne', sans-serif;
  font-size: 75px;
  color: #efd452;
  letter-spacing: 15px;
  font-weight: bold;
  width: 100%;
}

.principle-boxes-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* margin: 50px 0px; */
  padding: 0px;
  width: 100%;
}

.principle-box {
  font-family: 'Syne', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 38vh;
  width: 20vw;
  margin: 0vh 1vw;
}

.principle-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25vh;
  padding: 0px;
}

.tools-icons-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 3rem 0rem 1rem;
  font-size: 3vh;
  color: #efd552cb;
}

/* Responsive Design */
@media (max-width: 1626px) and (min-width: 1035px) {
  .principles-page {
    margin: 5vh 2vw;
    padding: 15px 0px;
    height: fit-content;
  }
  .principle-main-title {
    font-size: 65px;
    letter-spacing: 5px;
    margin: 0px;
    padding: 0px;
    font-weight: bold;
    text-align: center;
    padding: 0px;
    justify-content: center;
  }
}

@media (max-width: 1034px) and (min-width: 821px) {
  .principles-page {
    height: fit-content;
    align-items: center;
    justify-content: space-evenly;
    margin: 5vh 2vw;
    padding: 50px 0px 50px 0px;
  }

  .principle-main-title-container {
    padding: 0px;
    margin: 0px;
  }

  .principle-main-title {
    font-size: 45px;
    text-align: center;
    font-weight: bold;
    padding-top: 30px;
  }

  .principle-boxes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  .tools-icons-container {
    margin: 4rem 0vw 1rem;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .principles-page {
    padding: 50px 20px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    margin: 5vh 2vw;
  }
  .principle-main-title-container {
  }
  .principle-main-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    width: fit-content;
    letter-spacing: 6px;
  }

  .principle-boxes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    width: 100%;
  }

  .tools-icons-container {
    font-size: 2vh;
  }
}

@media (max-width: 640px) and (min-width: 431px) {
  .principles-page {
    padding: 50px 0px;
    height: fit-content;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin: 5vh 2vw;
  }
  .principle-main-title-container {
    text-align: center;
  }
  .principle-main-title {
    font-size: 30px;
  }

  .principle-boxes-container {
    flex-direction: column;
    height: fit-content;
  }

  .tools-icons-container {
    margin: 0rem 0rem 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 430px) {
  .principles-page {
    padding: 50px 0px;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin: 5vh 2vw;
    width: fit-content;
  }
  .principle-main-title-container {
    margin: 30px 0px 0px 0px;
    text-align: center;
  }
  .principle-main-title {
    font-size: 35px;
    letter-spacing: 10px;
  }

  .principle-boxes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .principle-description {
    font-size: 18px;
  }

  .tools-icons-container {
    margin: 0rem 0rem 2rem;
    font-size: 1rem;
  }
}
